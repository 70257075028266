var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "container card public-profile border-0 rounded shadow overflow-hidden",
    staticStyle: {
      "padding-right": "0px !important",
      "padding-left": "0px !important"
    }
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center",
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("hibah-reg-verification-list")) + " ")]), this.awaris_array.length > 0 ? _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-5"
  }, [_c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v("ID")]), _c('th', {
    staticStyle: {
      "width": "200px"
    }
  }, [_vm._v(_vm._s(_vm.$t("code")))]), _c('th', [_vm._v("AWARIS")]), _c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("total")))])])]), _vm._l(_vm.awaris_array, function (awaris) {
    return _c('tbody', {
      key: awaris.id
    }, [_c('tr', [_c('td', [_vm._v(_vm._s(awaris.id))]), _c('td', [_vm._v(_vm._s(awaris.code))]), _c('td', [_vm._v(_vm._s(awaris.short_name))]), _c('td', [_c('router-link', {
      attrs: {
        "to": {
          name: 'trust.pengesahan.list-pendaftaran',
          query: {
            organization_id: awaris.id
          }
        }
      }
    }, [_vm._v(_vm._s(awaris.total_pending))])], 1)])]);
  })], 2)]) : _vm._e(), this.bank_array.length != 0 ? _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-5"
  }, [_c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v("ID")]), _c('th', {
    staticStyle: {
      "width": "200px"
    }
  }, [_vm._v(_vm._s(_vm.$t("code")))]), _c('th', [_vm._v("Bank")]), _c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("total")))])])]), _vm._l(_vm.bank_array, function (bank) {
    return _c('tbody', {
      key: bank.id
    }, [_c('tr', [_c('td', [_vm._v(_vm._s(bank.id))]), _c('td', [_vm._v(_vm._s(bank.code))]), _c('td', [_vm._v(_vm._s(bank.short_name))]), _c('td', [_c('router-link', {
      attrs: {
        "to": {
          name: 'trust.pengesahan.list-pendaftaran',
          query: {
            organization_id: bank.id
          }
        }
      }
    }, [_vm._v(_vm._s(bank.total_pending))])], 1)])]);
  })], 2)]) : _vm._e(), this.koperasi_array.length != 0 ? _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-5"
  }, [_c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v("ID")]), _c('th', {
    staticStyle: {
      "width": "200px"
    }
  }, [_vm._v(_vm._s(_vm.$t("code")))]), _c('th', [_vm._v(_vm._s(_vm.$t("cooperative")))]), _c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("total")))])])]), _vm._l(_vm.koperasi_array, function (koperasi) {
    return _c('tbody', {
      key: koperasi.id
    }, [_c('tr', [_c('td', [_vm._v(_vm._s(koperasi.id))]), _c('td', [_vm._v(_vm._s(koperasi.code))]), _c('td', [_vm._v(_vm._s(koperasi.short_name))]), _c('td', [_c('router-link', {
      attrs: {
        "to": {
          name: 'trust.pengesahan.list-pendaftaran',
          query: {
            organization_id: koperasi.id
          }
        }
      }
    }, [_vm._v(_vm._s(koperasi.total_pending))])], 1)])]);
  })], 2)]) : _vm._e(), this.individu_array.length != 0 ? _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-5"
  }, [_c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v("ID")]), _c('th', {
    staticStyle: {
      "width": "200px"
    }
  }, [_vm._v(_vm._s(_vm.$t("code")))]), _c('th', [_vm._v(_vm._s(_vm.$t("individual")))]), _c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("total")))])])]), _c('tbody', _vm._l(_vm.individu_array, function (rakan) {
    return _c('tr', {
      key: rakan.id
    }, [_c('td', [_vm._v(_vm._s(rakan.id))]), _c('td', [_vm._v(_vm._s(rakan.code))]), _c('td', [_vm._v(_vm._s(rakan.short_name))]), _c('td', [_c('router-link', {
      attrs: {
        "to": {
          name: 'trust.pengesahan.list-pendaftaran',
          query: {
            organization_id: rakan.id
          }
        }
      }
    }, [_vm._v(_vm._s(rakan.total_pending))])], 1)]);
  }), 0)])]) : _vm._e(), this.pelanggan_array.length != 0 ? _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-5"
  }, [_c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v("ID")]), _c('th', {
    staticStyle: {
      "width": "200px"
    }
  }, [_vm._v(_vm._s(_vm.$t("code")))]), _c('th', [_vm._v("Bank")]), _c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("total")))])])]), _vm._l(_vm.pelanggan_array, function (pelanggan) {
    return _c('tbody', {
      key: pelanggan.id
    }, [_c('tr', [_c('td', [_vm._v(_vm._s(pelanggan.id))]), _c('td', [_vm._v(_vm._s(pelanggan.code))]), _c('td', [_vm._v(_vm._s(pelanggan.short_name))]), _c('td', [_c('router-link', {
      attrs: {
        "to": {
          name: 'trust.pengesahan.list-pendaftaran',
          query: {
            organization_id: pelanggan.id
          }
        }
      }
    }, [_vm._v(_vm._s(pelanggan.total_pending))])], 1)])]);
  })], 2)]) : _vm._e(), this.kerajaan_array.length != 0 ? _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-5"
  }, [_c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v("ID")]), _c('th', {
    staticStyle: {
      "width": "200px"
    }
  }, [_vm._v(_vm._s(_vm.$t("code")))]), _c('th', [_vm._v(_vm._s(_vm.$t("government")))]), _c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("total")))])])]), _c('tbody', _vm._l(_vm.kerajaan_array, function (rakan) {
    return _c('tr', {
      key: rakan.id
    }, [_c('td', [_vm._v(_vm._s(rakan.id))]), _c('td', [_vm._v(_vm._s(rakan.code))]), _c('td', [_vm._v(_vm._s(rakan.short_name))]), _c('td', [_c('router-link', {
      attrs: {
        "to": {
          name: 'trust.pengesahan.list-pendaftaran',
          query: {
            organization_id: rakan.id
          }
        }
      }
    }, [_vm._v(_vm._s(rakan.total_pending))])], 1)]);
  }), 0)])]) : _vm._e(), this.swasta_array.length != 0 ? _c('div', {
    staticClass: "table-responsive bg-white shadow rounded mt-5"
  }, [_c('table', {
    staticClass: "table mb-0 table-center"
  }, [_c('thead', {
    staticClass: "bg-light"
  }, [_c('tr', [_c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v("ID")]), _c('th', {
    staticStyle: {
      "width": "200px"
    }
  }, [_vm._v(_vm._s(_vm.$t("code")))]), _c('th', [_vm._v(_vm._s(_vm.$t("private")))]), _c('th', {
    staticStyle: {
      "width": "100px"
    }
  }, [_vm._v(_vm._s(_vm.$t("total")))])])]), _c('tbody', _vm._l(_vm.swasta_array, function (rakan) {
    return _c('tr', {
      key: rakan.id
    }, [_c('td', [_vm._v(_vm._s(rakan.id))]), _c('td', [_vm._v(_vm._s(rakan.code))]), _c('td', [_vm._v(_vm._s(rakan.short_name))]), _c('td', [_c('router-link', {
      attrs: {
        "to": {
          name: 'trust.pengesahan.list-pendaftaran',
          query: {
            organization_id: rakan.id
          }
        }
      }
    }, [_vm._v(_vm._s(rakan.total_pending))])], 1)]);
  }), 0)])]) : _vm._e()])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }